import React from "react";
import { BackgroundOverlay, Container } from "./elements";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel } from "swiper";
import HomepageTitle from "../HomepageTitle";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { AnimatedButton } from "../Buttons/elements";
import { HomepageButton } from "../HomepageButton/elements";
import { navigate } from "gatsby";

const HomepageMediaGallery = ({ mediaGallery, title, buttonText, background }) => {
  return (
    <Container background={background}>
      <HomepageTitle title={title} buttonText={buttonText} buttonSlug="media-gallery" />
      <Swiper
        className="mySwiper"
        direction="horizontal"
        spaceBetween={20}
        centeredSlides={true}
        centerInsufficientSlides={true}
        modules={[Autoplay, Mousewheel]}
        autoplay={{
          enabled: true,
          delay: 2000,
          // pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        speed={2500}
        loop={true}
        slidesPerView={"auto"}
      >
        {mediaGallery?.map((item) => (
          <SwiperSlide
            key={item.id}
            onClick={() => {
              navigate(`media-gallery`);
            }}
            // style={{ cursor: "pointer" }}
          >
            <img src={item?.localFile?.childImageSharp?.fixed?.src} alt={`Quoz arts fest - media gallery image`} />
            <BackgroundOverlay />
            {/* <InfoWrapper>
              <H4BigStrong style={{ color: "var(--primary)" }}>{item.title || ""}</H4BigStrong>
              <H5Big style={{ color: "var(--white)" }}>{item.participantLocation?.length ? item.participantLocation[0]?.title : ""}</H5Big>
            </InfoWrapper> */}
          </SwiperSlide>
        ))}
      </Swiper>

      <AnimationOnScroll
        className="btn-mobile-wrapper"
        animateIn="animate__fadeInRight"
        animateOut="animate__fadeOutRight"
        duration={0.75}
        offset={100}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AnimatedButton>
            <HomepageButton
              onClick={() => {
                navigate(`media-gallery`);
              }}
            >
              {buttonText}
            </HomepageButton>
          </AnimatedButton>
        </div>
      </AnimationOnScroll>
    </Container>
  );
};

export default HomepageMediaGallery;
