import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: fit-content;
  padding: 100px;
  color: var(--white);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  @media (max-width: 767px) {
    padding: 48px 20px;
    gap: 37px;
    & h3 {
      font-size: 24px;
    }
  }
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) -18.14%,
    rgba(0, 0, 0, 0.75) 50%
  );
  z-index: -1;
`;

export const CardList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  @media only screen and (max-width: 1309px) {
    justify-content: center;
    gap: 75px 150px;
  }
  @media (max-width: 767px) {
    gap: 25px;
  }
`;

export const Card = styled.div`
  position: relative;
  width: 350px;
  height: 300px;
  transition: all 0.3s;
  &:hover {
    cursor: var(--cursor-hover) 0 0, pointer;
    transform: scale(1.07);
    transition: all 0.3s;
  }
  @media (max-width: 767px) {
    width: 335px;
    height: 338px;
    & .image-wrapper {
      & > div {
        width: 282px !important;
        height: 338px !important;
      }
    }
  }
`;

export const CardContent = styled.div`
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 1;
  @media (max-width: 767px) {
    > h2 {
      font-size: 28px !important;
    }
    > h3 {
      font-size: 21px !important;
    }
  }
  @media (max-width: 767px) {
    bottom: 81px;
  }
`;
