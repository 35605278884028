import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--bg-color);

  .swiper {
    /* max-width: 1440px; */
    /* height: 100px; */
    width: 100%;
    padding: 30px 0;
    border-top: 2px solid var(--primary);
    border-bottom: 2px solid var(--primary);
    @media (max-width: 767px) {
      padding: 16px 0;
    }
  }

  .swiper-slide {
    cursor: var(--cursor-hover) 0 0, pointer !important;
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    filter: invert(100%);
  }

  .swiper-slide img {
    object-fit: contain !important;
  }

  @media (max-width: 767px) {
    padding: 16px 0;
    .swiper {
      height: unset;
      a {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .gatsby-image-wrapper {
      width: 150px !important;
      height: 55px !important;
    }
    .swiper-slide {
      width: 125px !important;
      // margin-right: 40px !important;
    }
  }
`;
