import React, { useEffect, useState } from "react";
import { BackgroundOverlay, Container, InfoWrapper } from "./elements";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel } from "swiper";
import Img from "gatsby-image";
import { H4BigStrong, H5Big } from "../Typography";
import HomepageTitle from "../HomepageTitle";
import { HomepageButton } from "../HomepageButton/elements";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { navigate } from "gatsby";
import { Overlay } from "../CommunityCardHolder";
import { AnimatedButton } from "../Buttons/elements";

const HomepageFood = ({ foodParticipants, title, buttonText, background }) => {
  const [overlayOn, setOverlayOn] = useState(false);
  const [currentlySelected, setCurrentlySelected] = useState(null);
  const [randomArray, setRandomArray] = useState([]);

  // useEffect(() => {
  //   const temp = [];
  //   for (let i = 0; i < foodParticipants?.length; i++) {
  //     let rand = Math.floor(Math.random() * (3 - 1 + 1) + 1);
  //     if (foodParticipants[i].qafParticipantType?.length && foodParticipants[i].qafParticipantType?.[0]?.title === "Food") {
  //       rand = -1;
  //     }
  //     temp.push(rand);
  //   }
  //   setRandomArray(temp);
  // }, []);
  return (
    <Container background={background}>
      <HomepageTitle title={title} buttonText={buttonText} buttonSlug="participants?type=food" />
      <Swiper
        className="mySwiper"
        direction="horizontal"
        spaceBetween={20}
        centeredSlides={true}
        centerInsufficientSlides={true}
        modules={[Autoplay, Mousewheel]}
        autoplay={{
          enabled: true,
          delay: 2000,
          // pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        speed={2500}
        loop={true}
        slidesPerView={3}
      >
        {foodParticipants?.map((item) => (
          <SwiperSlide
            key={item.id}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              // setCurrentlySelected(item);
              // setOverlayOn(true);
              navigate(`/participant/${item?.slug}`)
            }}
          >
            <Img
              alt={`Quoz arts fest - food participant - ${item?.title}`}
              fixed={item.headerImage?.length ? item.headerImage[0]?.localFile?.childImageSharp?.fixed : null}
              style={{ width: "100%", height: "100%" }}
            />
            <BackgroundOverlay />
            <InfoWrapper>
              <H4BigStrong style={{ color: "var(--primary)" }}>{item.title || ""}</H4BigStrong>
              {/* <H5Big style={{ color: "var(--text-color)" }}>{item.participantLocation?.length ? item.participantLocation[0]?.title : ""}</H5Big> */}
            </InfoWrapper>
          </SwiperSlide>
        ))}
      </Swiper>

      <AnimationOnScroll
        className="btn-mobile-wrapper"
        animateIn="animate__fadeInRight"
        animateOut="animate__fadeOutRight"
        duration={0.75}
        offset={100}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AnimatedButton>
            <HomepageButton
              onClick={() => {
                navigate(`participants?type=food`);
              }}
            >
              {buttonText}
            </HomepageButton>
          </AnimatedButton>
        </div>
      </AnimationOnScroll>

      {overlayOn ? (
        <Overlay
          communityMember={currentlySelected}
          closeThis={() => {
            setOverlayOn(false);
          }}
          randomNumber={randomArray[foodParticipants.indexOf(currentlySelected)]}
        />
      ) : null}
    </Container>
  );
};

export default HomepageFood;
