import React from "react";
import { Container } from "./elements";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import Img from "gatsby-image";

const HomepageSponsors = ({ sponsors }) => {
  return (
    <Container id="homepage-sponsors">
      <Swiper
        className="mySwiper"
        direction="horizontal"
        spaceBetween={20}
        centeredSlides={true}
        centerInsufficientSlides={true}
        modules={[Autoplay]}
        autoplay={{
          enabled: true,
          delay: 750,
          // pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        speed={2500}
        loop={true}
        slidesPerView={5}
      >
        {sponsors?.map((item) => (
          <SwiperSlide key={item.id}>
            <a href={item.logoLink || "/"} target="_blank">
              <Img
                fixed={
                  item.logo?.length
                    ? item.logo[0]?.localFile?.childImageSharp?.fixed
                    : null
                }
                style={{
                  width: "204px",
                  height: "70px",
                }}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default HomepageSponsors;
