import React, { useEffect, useRef } from "react";
import Img from "gatsby-image";
import Layout from "./../components/Layout";
import HomepageHero from "../components/HomepageHero";
import HomepageIntro from "../components/HomepageIntro";
import HomepageTheme from "../components/HomepageTheme";
import HomepageInfo from "../components/HomepageInfo";
import HomepageMusic from "../components/HomepageMusic";
import HomepageParticipants from "../components/HomepageParticipants";
import HomepageSponsors from "../components/HomepageSponsors";
import { graphql, navigate, useStaticQuery } from "gatsby";
import HomepageFood from "../components/HomepageFood";
import HomepageAudioPlayer from "../components/HomepageAudioPlayer";
import HomepageMediaGallery from "../components/HomepageMediaGallery";
import HomepageLastSection from "../components/HomepageLastSection";
import HomepageInstagram from "../components/HomepageInstagram";

const Homepage = () => {
  const homepageQuery = useStaticQuery(graphql`
    {
      craftQafHomepageQafHomepageEntry {
        descriptionSeo
        titleSeo
        showCounter
        showHeroBanner
        showHomepageFood
        showHomepageMap
        showHomepageParticipants
        showHomepageSoundcloud
        showHomepageSponsors
        showIntro
        showLastSection
        showMediaGallery
        showMusicEvent
        ticketButtonLink
        ticketButtonText
        mp4Video {
          url
        }
        webmVideo {
          url
        }
        heroImage {
          url
        }
        bannerImage {
          ... on Craft_communityAssets_Asset {
            localFile {
              childImageSharp {
                fluid(maxWidth: 960, quality: 100) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          ... on Craft_qafAssets_Asset {
            localFile {
              childImageSharp {
                fluid(maxWidth: 960, quality: 100) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        introTitle
        introDescription
        introButtonText
        introButtonLink {
          slug
        }
        counterSection {
          title
          number
        }
        homepageMap {
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        homepageSponsors {
          ... on Craft_homepageSponsors_sponsor_BlockType {
            id
            logo {
              ... on Craft_communityAssets_Asset {
                id
                localFile {
                  childImageSharp {
                    fixed(quality: 100) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_qafAssets_Asset {
                id
                localFile {
                  childImageSharp {
                    fixed(quality: 100) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            logoLink
          }
        }
        performerTitle: homepagePerformersTitle
        performerButton: performersButtonText
        performerBackground: performersBackgroundImage {
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(width: 1440, fit: COVER, quality: 100) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(width: 1440, fit: COVER, quality: 100) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        foodTitle: foodSectionTitle
        foodButton: foodSectionButtonText
        foodBackground: foodSectionBackgroundImage {
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 1440) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 1440) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        foodParticipants {
          ... on Craft_participants_default_Entry {
            id
            communityMemberSlug
            qafParticipantType {
              ... on Craft_qafParticipantType_default_Entry {
                id
                title
              }
            }
            communityMemberWebsite
            communityMemberEmailAddress
            subtitle
            communityMemberOpeningHours
            participantLocation {
              title
            }
            communityMemberDescription
            communityMemberContactDetails {
              method
              value
            }
            slug
            title
            headerImage: qafParticipantHeaderImage {
              ... on Craft_communityAssets_Asset {
                localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1000, quality: 100) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1000, quality: 100) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        homepageSoundcloud
        lastSectionBottomButtonLink
        lastSectionBottomButtonText
        lastSectionDescription
        lastSectionTitle
        lastSectionTopButtonLink
        lastSectionTopButtonText
        galleryTitle: mediaGallerySectionTitle
        galleryButton: imageGalleryButtonText
        galleryBackground: imageGalleryBackgroundImage {
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 1440) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 1440) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        mediaGallery {
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(quality: 100, height: 500) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(quality: 100,  height: 500) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        musicEvent {
          id
          ... on Craft_qafEvents_qafEvent_Entry {
            id
            title
            eventStartDateTime
            eventEndDateTime
            slug
            qafEventLocation {
              title
            }
            headerImage: qafEventHeaderImage {
              ... on Craft_eventsAssets_Asset {
                id
                localFile {
                  childImageSharp {
                    fixed(quality: 100, width: 1000) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        participantsTitle: participantsSectionTitle
        participantsButton: participantsSectionButtonText
        participantsBackground: participantsBackgroundImage {
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 1440) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 1440) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        instagramButtonText
        instagramLink
        instagrampost {
          ... on Craft_instagrampost_singleInstagramPost_BlockType {
            instagramPostImage {
              ... on Craft_qafAssets_Asset {
                id
                localFile {
                  childImageSharp {
                    fixed(quality: 100, height: 590) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                id
                localFile {
                  childImageSharp {
                    fixed(quality: 100, height: 590) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            instagramPostLink
          }
        }
      }
      events: allCraftQafEventsQafEventEntry {
        nodes {
          id
          title
          isStaging
          eventEndDateTime
          eventStartDateTime
          eventShortDescription
          qafEventLocation {
            title
            ... on Craft_participants_default_Entry {
              participantLocation {
                title
              }
              communityMemberOpeningHours
              communityMemberSlug
              communityMemberWebsite
              communityMemberEmailAddress
              communityMemberDescription
              communityMemberContactDetails {
                col1
                col2
              }
              qafParticipantType {
                ... on Craft_qafParticipantType_default_Entry {
                  id
                  title
                }
              }
            }
            ... on Craft_maplocation_default_Entry {
              locationId
              locationName
              title
              allowClick
            }
          }
          slug
          qafEventType {
            ... on Craft_qafEventType_default_Entry {
              id
              title
            }
          }
          headerImage: qafEventHeaderImage {
            ... on Craft_eventsAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(fit: COVER, width: 172) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      participants: allCraftParticipantsDefaultEntry(filter: { isDraft: { eq: false } }) {
        nodes {
          id
          communityMemberSlug
          qafParticipantType {
            ... on Craft_qafParticipantType_default_Entry {
              id
              title
            }
          }
          communityMemberWebsite
          communityMemberEmailAddress
          subtitle
          communityMemberOpeningHours
          participantLocation {
            title
          }
          communityMemberDescription
          communityMemberContactDetails {
            method
            value
          }
          slug
          title
          headerImage: qafParticipantHeaderImage {
            ... on Craft_communityAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            ... on Craft_eventsAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const now = new Date();
  const data = homepageQuery?.craftQafHomepageQafHomepageEntry;
  const events = homepageQuery?.events?.nodes?.filter((item) => {
    const thisDate = new Date(item.eventEndDateTime || item.eventStartDateTime || null);
    return thisDate >= now;
  });

  const participants = homepageQuery?.participants?.nodes;

  const titleSeo = data?.titleSeo;
  const descriptionSeo = data?.descriptionSeo;

  //Hero banner
  const mp4Url = data?.mp4Video?.length ? data?.mp4Video[0]?.url : null;
  const webmUrl = data?.webmVideo?.length ? data?.webmVideo[0]?.url : null;
  const heroImageUrl = data?.heroImage?.length ? data?.heroImage[0]?.url : null;
  const bannerImage = data?.bannerImage?.length ? data?.bannerImage[0]?.localFile?.childImageSharp?.fluid : null;

  const introTitle = data?.introTitle || null;
  const ticketButtonLink = data?.ticketButtonLink || null;
  const ticketButtonText = data?.ticketButtonText || null;
  const introDescription = data?.introDescription || null;
  const introButtonText = data?.introButtonText || null;
  const introButtonSlug = data?.introButtonLink?.length ? data?.introButtonLink[0]?.slug : null;

  // const homepageEventsBackground = data?.homepageEventsBackground?.length
  //   ? data?.homepageEventsBackground[0]?.localFile?.childImageSharp?.fixed
  //   : null;
  // const homepageEventsTitle = data?.homepageEventsTitle || null;
  // const homepageEvents = data?.homepageEvents || null;

  const counterList = data?.counterSection || null;

  const homepageSponsors = data?.homepageSponsors || null;

  //Performers
  const musicEvent = data?.musicEvent || null;
  const performerTitle = data?.performerTitle || "Performers";
  const performerButton = data?.performerButton || "SHOW ALL";
  const performerBackground = data?.performerBackground?.[0]?.localFile?.childImageSharp?.fixed?.src || null;

  const homepageMap = data?.homepageMap?.length ? data?.homepageMap[0]?.localFile?.childImageSharp?.fluid : null;

  let homepageSoundcloud = data?.homepageSoundcloud || null;

  //Food Section
  const foodTitle = data?.foodTitle || "Food";
  const foodButton = data?.foodButton || "SHOW ALL";
  const foodBackground = data?.foodBackground?.[0]?.localFile?.childImageSharp?.fixed?.src || null;
  const foodParticipants = data?.foodParticipants || null;

  //Gallery Section
  const galleryTitle = data?.galleryTitle || "gallery";
  const galleryButton = data?.galleryButton || "SHOW ALL";
  const galleryBackground = data?.galleryBackground?.[0]?.localFile?.childImageSharp?.fixed?.src || null;
  const mediaGallery = data?.mediaGallery || null;

  //Food Section
  const participantsTitle = data?.participantsTitle || "Participants";
  const participantsButton = data?.participantsButton || "SHOW ALL";
  const participantsBackground = data?.participantsBackground?.[0]?.localFile?.childImageSharp?.fixed?.src || null;

  //Last Section
  const lastSectionBottomButtonLink = data?.lastSectionBottomButtonLink;
  const lastSectionBottomButtonText = data?.lastSectionBottomButtonText;
  const lastSectionDescription = data?.lastSectionDescription;
  const lastSectionTitle = data?.lastSectionTitle;
  const lastSectionTopButtonLink = data?.lastSectionTopButtonLink;
  const lastSectionTopButtonText = data?.lastSectionTopButtonText;

  //Instagram section
  const instagramButton = data?.instagramButtonText ?? "INSTAGRAM";
  const instagramLink = data?.instagramLink ?? null;
  const instagramPost = data?.instagrampost ?? null;

  const showHeroBanner = data?.showHeroBanner;
  const showIntro = data?.showIntro;
  const showCounter = data?.showCounter;
  const showMusicEvent = data?.showMusicEvent;
  const showHomepageSponsors = data?.showHomepageSponsors;
  const showImageGallery = data?.showMediaGallery;
  const showLastSection = data?.showLastSection;
  const showHomepageSoundcloud = data?.showHomepageSoundcloud;
  const showHomepageMap = data?.showHomepageMap;
  const showHomepageFood = data?.showHomepageFood;
  const showHomepageParticipants = data?.showHomepageParticipants;
  const showInstagramSection = data?.showInstagramSection;

  useEffect(() => {
    for (let i = 0; i < foodParticipants?.length; i++) {
      const relatedE = events.filter((e) => {
        const checkedArray = e?.qafEventLocation?.length
          ? e?.qafEventLocation.filter((item) => item?.qafParticipantType && item?.qafParticipantType?.length)
          : [];

        return checkedArray.find((item) => item?.id === foodParticipants[i]?.id);
      });
      foodParticipants[i].relatedEvents = relatedE;
    }

    for (let i = 0; i < participants?.length; i++) {
      const relatedE = events.filter((e) => {
        const checkedArray = e?.qafEventLocation?.length
          ? e?.qafEventLocation.filter((item) => item?.qafParticipantType && item?.qafParticipantType?.length)
          : [];

        return checkedArray.find((item) => item?.id === participants[i]?.id);
      });
      participants[i].relatedEvents = relatedE;
    }
  }, []);

  return (
    <Layout backgroundColor={"var(--bg-color)"} skipHeader headerBlack={true} title={titleSeo} SEODescription={descriptionSeo}>
      {showHeroBanner ? <HomepageHero mp4Url={mp4Url} webmUrl={webmUrl} heroImageUrl={heroImageUrl} bannerImage={bannerImage} /> : null}

      {showIntro ? (
        <HomepageIntro
          introTitle={introTitle}
          introDescription={introDescription}
          introButtonText={introButtonText}
          introButtonSlug={introButtonSlug}
          ticketButtonText={ticketButtonText}
          ticketButtonLink={ticketButtonLink}
        />
      ) : null}

      {showCounter ? <HomepageInfo counterList={counterList} /> : null}

      {showHomepageSponsors ? <HomepageSponsors sponsors={homepageSponsors} /> : null}

      {musicEvent?.length > 0 && showMusicEvent ? (
        <HomepageMusic events={musicEvent} title={performerTitle} buttonText={performerButton} background={performerBackground} />
      ) : null}

      {showHomepageMap ? (
        <div
          style={{
            margin: "auto",
            width: "100%",
            height: "100%",
            maxHeight: "879px",
            maxWidth: "1440px",
            cursor: 'var(--cursor-hover) 0 0, pointer'
          }}
          onClick={() => navigate("/map")}
        >
          <Img
            fluid={homepageMap}
            alt="Quoz arts fest map at Alserkal Avenue"
            style={{
              maxHeight: "879px",
              maxWidth: "1440px",
              minHeight: "700px",
            }}
          />
        </div>
      ) : null}

      {showHomepageFood && foodParticipants?.length ? (
        <HomepageFood foodParticipants={foodParticipants} title={foodTitle} buttonText={foodButton} background={foodBackground} />
      ) : null}

      {showImageGallery ? (
        <HomepageMediaGallery mediaGallery={mediaGallery} title={galleryTitle} buttonText={galleryButton} background={galleryBackground} />
      ) : null}

      {showHomepageParticipants ? (
        <HomepageParticipants
          participants={participants}
          title={participantsTitle}
          buttonText={participantsButton}
          background={participantsBackground}
        />
      ) : null}

      {showLastSection ? (
        <HomepageLastSection
          lastSectionBottomButtonLink={lastSectionBottomButtonLink}
          lastSectionBottomButtonText={lastSectionBottomButtonText}
          lastSectionDescription={lastSectionDescription}
          lastSectionTitle={lastSectionTitle}
          lastSectionTopButtonLink={lastSectionTopButtonLink}
          lastSectionTopButtonText={lastSectionTopButtonText}
        />
      ) : null}

      {showHomepageSoundcloud ? <HomepageAudioPlayer homepageSoundcloud={homepageSoundcloud} /> : null}

      {/* {showHomepageEvent ? <HomepageTheme backgroundImage={homepageEventsBackground} title={homepageEventsTitle} themes={homepageEvents} /> : null} */}

      {showInstagramSection ?? instagramPost?.length ? (
        <HomepageInstagram instagramPost={instagramPost} buttonText={instagramButton} url={instagramLink} />
      ) : null}
    </Layout>
  );
};

export default Homepage;
