import styled from "styled-components";

export const Container = styled.div`
  padding: 50px 0;
  border-top: 1px solid var(--primary);
  background-color: var(--bg-color);

  .swiper {
    box-sizing: border-box;
    height: 295px;
    margin: 0 100px 35px 100px;
  }

  .swiper-slide {
    cursor: var(--cursor-hover) 0 0, pointer !important;
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    a {
      width: 100%;
      height: 100%;
    }
    img {
      object-fit: cover !important;
    }
  }

  .btn-mobile-wrapper {
    display: none;
  }
  .instagramButton {
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    padding: 36px 20px;
    .btn-mobile-wrapper {
      margin: 36px 20px 0;
      display: block;
      & > div {
        & > button {
          flex: 1;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .swiper {
      min-width: 600px;
      height: 228px;
      margin: 0 0 36px 20px;
    }
  }
`;

export const InfoWrapper = styled.div`
  position: absolute;
  left: 24px;
  bottom: 18px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  width: unset !important;
  min-width: unset !important;
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 35.22%);
`;
