import React from "react";
import { BackgroundOverlay, Container } from "./elements";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel } from "swiper";
import Img from "gatsby-image";
import { HomepageButton } from "../HomepageButton/elements";
import { AnimatedButton } from "../Buttons/elements";

const HomepageInstagram = ({ instagramPost, buttonText, url }) => {
  return (
    <Container>
      <Swiper
        className="mySwiper"
        direction="horizontal"
        spaceBetween={20}
        centeredSlides={false}
        modules={[Autoplay, Mousewheel]}
        autoplay={{
          enabled: true,
          delay: 2000,
          disableOnInteraction: false,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        speed={1500}
        loop={true}
        breakpoints={{
          1: {
            slidesPerView: 2,
            centeredSlides: false,
            allowSlidePrev: true,
            allowSlideNext: true,
          },
          1200: {
            slidesPerView: 4,
            allowSlidePrev: false,
            allowSlideNext: false,
          },
        }}
      >
        {instagramPost?.map((post, postIndex) => (
          <SwiperSlide key={postIndex}>
            <a href={post?.instagramPostLink} target="_blank">
              <Img fixed={post?.instagramPostImage?.[0]?.localFile?.childImageSharp?.fixed} style={{ width: "100%", height: "100%" }} alt="Quoz arts fest - Instagram post" />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
      <a href={url}>
        <AnimatedButton className={"instagramButton"}>
          <HomepageButton>{buttonText}</HomepageButton>
        </AnimatedButton>
      </a>
    </Container>
  );
};

export default HomepageInstagram;
