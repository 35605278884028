import React from "react";
import Img from "gatsby-image";
import { BannerContainer, Container, Video, VideoWrapper } from "./elements";
import { AnimationOnScroll } from "react-animation-on-scroll";

const HomepageHero = ({ mp4Url, webmUrl, heroImageUrl, bannerImage }) => {
  return (
    <Container>
      <VideoWrapper>
        <Video autoPlay muted loop playsInline backgroundImage={heroImageUrl}>
          {/* MP4 */}
          <source src={mp4Url} type="video/mp4" data-wf-ignore="true" />
          {/* WEBM */}
          <source src={webmUrl} type="video/webm" data-wf-ignore="true" />
        </Video>
      </VideoWrapper>
      
      {bannerImage ? (
        <BannerContainer>
          <div
            style={{
              height: "100%",
              width: "100%",
              maxWidth: "960px",
              maxHeight: "240px",
            }}
          >
            <AnimationOnScroll animatePreScroll={true} animateIn="animate__fadeInLeft" animateOut="animate__fadeOutRight" duration={1.5} offset={200}>
              <Img
                style={{
                  pointerEvents: "none",
                  flex: 1,
                  maxWidth: "960px",
                  maxHeight: "240px",
                }}
                fluid={bannerImage}
                alt="Hero Banner"
              />
            </AnimationOnScroll>
          </div>
        </BannerContainer>
      ) : null}
    </Container>
  );
};

export default HomepageHero;
